import React from "react"

import Layout from "../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

import SEO from "../components/seo"
import Img from "gatsby-image"

import { graphql, Link } from "gatsby"

export default ({ data, location, pageContext }) => (
  <Layout>
    <SEO
      pagetitle={`${pageContext.catname}`}
      pagedesc={`「${pageContext.catname}」カテゴリーの記事です`}
      pagepath={location.pathname}
    />
    <section className="content bloglist">
      <div className="container">
        <h1 className="bar">{pageContext.catname}</h1>
        <div className="posts">
          {data.allAirtableArticles.edges.map(({ node }) => {
            const imageFluid = node.data.eyecatch?.localFiles[0]
              ?.childImageSharp?.fluid
              ? node.data.eyecatch.localFiles[0].childImageSharp.fluid
              : data.file.childImageSharp.fluid

            return (
              <article className="post" key={node.data.id}>
                <Link to={`/${node.data.tags.join("/")}/${node.data.slug}`}>
                  <figure>
                    <Img
                      fluid={imageFluid}
                      alt={node.data.titleJa}
                      style={{ height: "100%" }}
                    />
                  </figure>
                  <h3>{node.data.titleJa}</h3>
                </Link>
              </article>
            )
          })}
        </div>
        <ul className="pagenation">
          {!pageContext.isFirst && (
            <li className="prev">
              <Link
                to={
                  pageContext.currentPage === 2
                    ? `/${pageContext.catpath}/`
                    : `/${pageContext.catpath}/${pageContext.currentPage - 1}/`
                }
                rel="prev"
                className="text-xl"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>前のページ</span>
              </Link>
            </li>
          )}
          {!pageContext.isLast && (
            <li className="next">
              <Link
                to={`/${pageContext.catpath}/${pageContext.currentPage + 1}/`}
                rel="next"
                className="text-xl"
              >
                <span> 次のページ</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query($catslug: String!, $skip: Int!, $limit: Int!) {
    file(relativePath: { eq: "dummy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allAirtableArticles(
      skip: $skip
      limit: $limit
      filter: { data: { tags: { in: [$catslug] } } }
    ) {
      edges {
        node {
          data {
            slug
            tags
            titleEn
            titleJa
            eyecatch {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 150) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
